import React from 'react';
import './App.css';

import Dashboard from './features/dashboard/Dashboard';
import TheLayout from './layout/TheLayout';
import Login from './layout/login/Login';

export default function App() {
  return (
    // <TheLayout>
    //   <Dashboard />
    // </TheLayout>
    <Login></Login>
  );
}
