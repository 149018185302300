import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginForm from './LoginForm';
import Copyright from '../Copyright';

const theme = createTheme();

function Header() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        overflow: 'hidden',
        fontWeight: 'bold',
      }}
    >
      <Avatar alt="Persinas ASAD" src="/images/logo.png" sx={{ width: 80, height: 80 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          m: 3,
        }}
      >
        <Typography variant="subtitle1">Monitoring Evaluasi (MONEV)</Typography>
        <Typography variant="body1" sx={{ color: 'primary.main' }}>
          Pengprov Persinas ASAD
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'primary.light' }}>
          Jawa Timur
        </Typography>
      </Box>
    </Box>
  );
}

export default function Login() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          }}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Header />
            <LoginForm />
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            backgroundImage: 'url(/images/flyer.gif)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: { xs: 'contain', md: '95%' },
            backgroundPosition: 'center',
            m: { xs: 1, sm: 2, md: 0 },
            minHeight: { xs: 200, sm: 300 },
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
